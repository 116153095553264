// importing components from react-router-dom package
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// import Home component

import Main2 from "./Main2";
// import About component
import Login from "./Components/Login";
// import ContactUs component
import Signup from "./Components/Signup";
import Campaign from "./Components/Campaign";
import Start from "./StartCamapaign/Start";
import Profile from "./Profile/Profile";
import Upload from "./Upload/Upload";
import Setting from "./Components2/CampaignEditor/Setting";
import Main from "./Main";
import FAQs from "./Components/FAQs";
import Terms from "./Components/terms";
import Client1 from "./Components/Client1";
import Contact from "./Components/Contact";
import NewApp from "../src/App copy";
import Welcome from "./Components/Welcome";

// import { Main as Upload } from "./Main2";
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/welcome" element={<Main />} />
          <Route exact path="/" element={<Welcome />} />
          <Route path="/about" element={<Login />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/sieze" element={<Main2 />} />
          <Route path="/startcampaign" element={<Start />} />
          <Route path="/faq" element={<FAQs />} />
          <Route path="/egoversus" element={<Client1 />} />
          <Route path="/t&c" element={<Terms />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Setting" element={<Setting />} />
          {/* <Route path="/Upload/*" element={<Upload />} /> */}
          <Route path="/Upload/*" element={<NewApp />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
