import axios from 'axios';
import React, { useState } from 'react';
// import './Client2.css';
import { FaRocket, FaEnvelope, FaTwitter, FaDiscord } from 'react-icons/fa';

const Welcome = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
const handleSubmit=async(e)=>{
  e.preventDefault();
  try {
    const response=await axios.post('http://localhost:8001/api/create-user',{name,email});
    console.log(response.data);
    setName('');
    setEmail('');
    
  } catch (error) {
    console.log(error);
    
  }
}
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#0a0a0a] to-[#1a0b2e] text-white flex flex-col items-center justify-center p-4">
      <div className="max-w-4xl w-full space-y-12 text-center">
        {/* Hero Section */}
        <div className="space-y-6">
          <FaRocket className="text-6xl mx-auto text-[#5b09d3] animate-bounce" />
          <h1 className="text-6xl md:text-8xl font-bold">
            <span className="glitch-text" data-text="SEIZE">SEIZE</span>
          </h1>
          <div className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-[#9f6eff] to-[#5b09d3] text-transparent bg-clip-text animate-pulse">
            COMING SOON
          </div>
        </div>

        {/* Description */}
        <div className="space-y-4 max-w-2xl mx-auto">
          <p className="text-xl text-[#a0a0a0]">
            Something revolutionary is brewing. Be the first to know when we launch and get exclusive early access.
          </p>
        </div>

        {/* Signup Form */}
        <div className="bg-[#1a0b2e]/50 p-8 rounded-2xl backdrop-blur-lg max-w-md mx-auto">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="space-y-4">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Your Name"
                className="w-full px-4 py-3 bg-[#2a1b4e] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5b09d3] transition-all"
              />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your Email"
                className="w-full px-4 py-3 bg-[#2a1b4e] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5b09d3] transition-all"
              />
            </div>
            <button
              type="submit" 
              className="w-full py-3 bg-gradient-to-r from-[#5b09d3] to-[#9f6eff] rounded-lg font-bold hover:shadow-lg hover:shadow-[#5b09d3]/50 transition-all duration-300"
            >
              Join the Waitlist
            </button>
          </form>
        </div>

        {/* Contact & Social */}
        <div className="space-y-6">
          <p className="text-[#a0a0a0]">
            For partnership inquiries: {' '}
            <a href="mailto:seize@seize.fund" className="text-[#9f6eff] hover:text-[#5b09d3] transition-colors">
              seize@seize.fund
            </a>
          </p>
          <div className="flex justify-center space-x-6">
            <a href="#" className="social-icon-link">
              <FaTwitter className="text-2xl text-[#9f6eff] hover:text-[#5b09d3] transition-colors" />
            </a>
            <a href="#" className="social-icon-link">
              <FaDiscord className="text-2xl text-[#9f6eff] hover:text-[#5b09d3] transition-colors" />
            </a>
            <a href="mailto:seize@seize.fund" className="social-icon-link">
              <FaEnvelope className="text-2xl text-[#9f6eff] hover:text-[#5b09d3] transition-colors" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
