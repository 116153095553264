import React from 'react'
import './Mycard.css'
const Mycard = ({cardno,image,title}) => {
    return (
        <div className='mycard'> 
        
        <img src={image} alt="" />
        <div className='card-content'>
            <h3>Card {cardno}</h3>
            {/* <p>{title}</p> */}
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium nemo, explicabo aut nobis quos minus?</p>
            <button className='btn'>Read More</button>
            <div className='popups'>

            <ul className='bg-black'>
														<li><a href="#"><i class="fas fa-shopping-cart"></i></a></li>
														<li><a href="#"><i class="fas fa-heart"></i></a></li>
														<li><a href="#"><i class="fas fa-plus"></i></a></li>
														<li><a href="#"><i class="fas fa-expand"></i></a></li>
												</ul>
            </div>

        </div>
        
        </div>
    )
}

export default Mycard