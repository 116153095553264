import React, { useState } from 'react'
import logo from './image/S.webp'
import '../Styles/Login.css'
import { useNavigate } from 'react-router-dom'
import axios from "axios"

const Signup = () => {
	const [name,setName]=useState("")
	const [email,setEmail]=useState("")
	const [password,setPassword]=useState("")
	const handleSignup=(e)=>{
		e.preventDefault();
		axios.post("https://344a2f8ae3c88eab663b8f97522ff213.serveo.net/signup",{name,email,password}).then(
			result=>{if(result.status==200){
				console.log("User created succesfully");
				navigate('/sieze')
				
			}}
		)
		.catch(err=>{if(err.response&&(err.res.status===400)){
			window.alert("Email Already Exist Please Try A Different One")
			
		}
	else{
		console.log(err);
		
	}})
	}
  const navigate=useNavigate()
  return (
    <div>
      <div class="box bg-black max-h-[20px] ">
	<form onSubmit={handleSignup}>
		<div className=' relative flex items-center  justify-center'>
			<img className='w-36 mb-16' src={logo} alt="" />
		</div>
		<span class="text-center relative font-bold whitespace-nowrap">Welcome to Seize </span>
	<div class="input-container">
		<input onChange={(e)=>setName(e.target.value)} required name="name" type="text" />
		<label>Full Name</label>		
	</div>
	<div class="input-container">		
		<input  onChange={(e)=>setEmail(e.target.value)} type="mail" required name='email'/> 
		<label>Email</label>
	</div>
  <div class="input-container">		
		<input  onChange={(e)=>setPassword(e.target.value)} type="mail" required name='password'/>
		<label>Password</label>
	</div>
		<button     type="submit" class="btn  flex items-center justify-center absolute ml-[60px] !rounded-lg">
		 
			submit
			
			</button>
			<div className='flex items-center justify-center mt-5 mb-5 text-white'>
				Or
			</div>
			<div className='flex items-center justify-center border-solid border-2 border-[#e74c3c] rounded-lg text-white py-2 hover:bg-[#e74c3c] hover:text-black hover:cursor-pointer'>
				<div className=''>
<img className='w-7' src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" alt="" />
				</div>
				<div >

				Continue with Google
				</div>
			</div>
<div className='text-white flex items-center justify-center relative mt-4'>
	Have an Account?  <button onClick={()=>navigate('/about')} className='font-semibold text-[#e74c3c]'>Login</button>
</div>
</form>	
</div>
{/* <div>hi</div> */}
    </div>
  )
}

export default Signup
