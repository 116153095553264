import React, { useEffect, useRef, useState } from "react";
import "./Start.css";
import Navbar from "../Components/Navbar";
import { useNavigate } from "react-router-dom";
import {
  FaRocket,
  FaTools,
  FaUserTie,
  FaChartLine,
  FaHandshake,
} from "react-icons/fa";
import backgroundVideo from "../images/h.mp4"; // Ensure you have this video file

const Start = () => {
  const navigate = useNavigate();
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className="relative min-h-screen overflow-hidden">
      <video
        autoPlay
        loop
        muted
        className="absolute z-0 w-auto min-w-full min-h-full max-w-none"
      >
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="absolute inset-0 bg-black opacity-60 z-10"></div>

      <div className="relative z-20">
        <Navbar />
        <div
          ref={sectionRef}
          className={`flex flex-col items-center justify-center py-20 px-4 sm:px-6 lg:px-8 transition-opacity duration-1000 ${
            isVisible ? "opacity-100" : "opacity-0"
          }`}
        >
          <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold text-center mb-12 tracking-tight text-white py-9">
            Every innovation
            <span className="block mt-4">
              has its
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-pink-500 ml-3 inline-block transform hover:scale-110 transition-transform duration-300 cursor-default">
                beginning
              </span>
            </span>
          </h1>

          <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-3xl p-8 sm:p-10 lg:p-12 shadow-2xl hover:shadow-red-500/30 transition-all duration-500 max-w-4xl w-full mx-4 sm:mx-auto transform hover:-translate-y-2">
            <h2 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center text-white py-4">
              Raise Funds with
            </h2>
            <h3 className="text-3xl xs:text-4xl sm:text-5xl lg:text-6xl font-extrabold mb-6 sm:mb-8 lg:mb-10 text-center bg-clip-text text-transparent bg-gradient-to-r from-red-500 via-purple-500 to-pink-500 animate-gradient-x py-4 sm:py-6">
              CrowdFunding
            </h3>

            <p className="text-lg sm:text-xl mb-10 sm:mb-12 text-center leading-relaxed text-white">
              <span className="font-semibold text-xl sm:text-2xl block mb-4">
                Launch. Fund. Build.
              </span>
              Ignite your vision, secure backing, and cultivate a thriving
              community
            </p>

            <ul className="space-y-6 sm:space-y-8 mb-10 sm:mb-12">
              {[
                {
                  icon: <FaRocket />,
                  text: "Flexible funding options tailored to your needs",
                },
                {
                  icon: <FaTools />,
                  text: "Powerful campaign customization tools at your fingertips",
                },
                {
                  icon: <FaUserTie />,
                  text: "Expert campaign strategists to guide your journey",
                },
                {
                  icon: <FaChartLine />,
                  text: "Real-time analytics to track your progress",
                },
                {
                  icon: <FaHandshake />,
                  text: "Vibrant community of supporters and fellow innovators",
                },
              ].map((item, index) => (
                <li
                  key={index}
                  className={`flex items-center space-x-5 text-base sm:text-lg text-white transition-transform duration-700 ${
                    isVisible
                      ? "transform translate-y-0"
                      : "transform translate-y-10"
                  }`}
                >
                  <span className="text-red-400 text-xl sm:text-2xl">
                    {item.icon}
                  </span>
                  <span className="flex-1">{item.text}</span>
                </li>
              ))}
            </ul>

            <button
              onClick={() => navigate("/Upload")}
              className="w-full bg-gradient-to-r from-red-500 via-purple-500 to-pink-500 text-white font-bold py-4 sm:py-5 px-8 sm:px-10 rounded-full text-lg sm:text-xl transition-all duration-300 hover:from-red-600 hover:via-purple-600 hover:to-pink-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 shadow-lg"
            >
              Launch Your Campaign
            </button>
          </div>
        </div>
      </div>

      {/* Animated particles */}
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden z-30 pointer-events-none">
        {[...Array(50)].map((_, i) => (
          <div key={i} className="particle"></div>
        ))}
      </div>
    </div>
  );
};

export default Start;
