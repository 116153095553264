import React from 'react'
import '../Styles/Login.css'
import logo from './image/S.webp'
import { useNavigate } from 'react-router-dom'

const Login = () => {
	const navigate=useNavigate()
  return (
    <div className='bg-black max-h-[20px]'>
      <div class="box ">
	<form>
		<div className=' relative flex items-center  justify-center'>
			<img className='w-36 mb-16' src={logo} alt="" />
		</div>
		<span class="text-center relative font-bold whitespace-nowrap">Welcome to Seize </span>
	<div class="input-container">
		<input  type="text" required=""/>
		<label >Full Name</label>		
	</div>
	<div class="input-container">		
		<input type="mail" required=""/>
		<label>Email</label>
	</div>
	
		<button onClick={()=>navigate('/sieze')}   type="button" class="btn !rounded-lg flex items-center justify-center absolute ml-[60px]">
			 
			submit
			
			</button>
			<div className='flex items-center justify-center mt-5 mb-5 text-white'>
				Or
			</div>
			<div className='flex items-center justify-center border-solid border-2 border-[#e74c3c] rounded-lg text-white py-2 hover:bg-[#e74c3c] hover:text-black hover:cursor-pointer'>
				<div className=''>
<img className='w-7' src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" alt="" />
				</div>
				<div >

				Continue with Google
				</div>
			</div>
<div className='text-white flex items-center justify-center relative mt-4'>
New to the Family?  <button onClick={()=>navigate('/signup')} className='font-semibold text-[#e74c3c]'>Signup</button>
</div>
</form>	
</div>
{/* <div>hi</div> */}
    </div>
  )
}

export default Login
