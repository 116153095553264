"use client";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import img1 from "./Components/image/s1.jpeg";
import img2 from "./Components/image/s2.jpeg";

import img6 from "./Components/image/bg102.webp";
import img7 from "./Components/image/bg101.webp";
import React, { useState, useEffect } from "react";

// import './Styles/Card.css'

import "react-alice-carousel/lib/alice-carousel.css";

import { useNavigate } from "react-router-dom";

import UpperPart2 from "./Components/UpperPart2";

import Imagecarousel from "./Products/Imagecarousel";

function App() {
  const navigate = useNavigate();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState("next");
  const [autoNextTimeout, setAutoNextTimeout] = useState(null);
  const [animationTimeout, setAnimationTimeout] = useState(null);
  const images = [img1, img2, img6, img7];
  const showSlider = (type) => {
    setDirection(type);
    if (type === "next") {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
    clearTimeout(animationTimeout);
    setAnimationTimeout(
      setTimeout(() => {
        setDirection("");
      }, timeRunning)
    );
    clearTimeout(autoNextTimeout);
    setAutoNextTimeout(
      setTimeout(() => {
        showSlider("next");
      }, timeAutoNext)
    );
  };

  const timeRunning = 3000;
  const timeAutoNext = 7000;
  useEffect(() => {
    return () => {
      clearTimeout(animationTimeout);
      clearTimeout(autoNextTimeout);
    };
  }, [currentSlide, animationTimeout, autoNextTimeout]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <div>
        <div>
          <UpperPart2 showCustomButton={true} />
        </div>
      </div>
      <div className="bg-white pt-16">
        <div className="round2 pb-8 text-[#ff5757]">Popular Campaigns</div>
        {/* <MainSlider */}
        <Imagecarousel />

        <div className="w-full px-8">
          <div className="round2b">
            <div className="flex items-center justify-center py-4">
              <div className="hover:bg-[#FFFFFF] hover:!text-[#ff5757]   flex items-center justify-center hover:cursor-pointer  text-white font-bold text-xl border-2 border-solid border-black bg-black  shadow-black shadow-2xl w-72 py-4 rounded-lg ">
                <button className="" onClick={() => navigate("/campaign")}>
                  EXPLORE ALL CAMPAIGNS
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-black text-white">
          <div className="text-white pt-32 font-bold text-4xl flex items-center justify-center ">
            Get the Latest Opportunities in your inbox
          </div>
          <p className="text-white py-5   text-2xl flex items-center justify-center">
            Exclusive weekly updates with new products, drops, and special
            offers.
          </p>
          <div className="py-5 flex items-center justify-center">
            <input
              className=" searchbar !items-center  rounded-lg h-12  text-gray-700   "
              type="text"
              placeholder="  Your email address"
            />
            <button className="bg-[#ff5757] ml-3 rounded-lg w-36 h-12">
              SIGN ME UP
            </button>
          </div>
          <div className="sh flex items-center justify-center pb-32  ">
            By clicking “Sign me up” I have read and agree to Seize’s Terms of
            Use and Privacy Policy .
          </div>
        </div>
        {/* <div className='border-b-2 border-solid border-[#4c2754] flex items-center justify-around boxkab'>

            </div>
        <div className='  py-52 border bottom-2 border-solid  border-red-700 '>
           
        </div>
          <div className='text-red-400 border-b-2 border-solid border-[#4c2754] flex items-center justify-around boxkab '>
        
          </div> */}
        <div className=" text-white bg-black  justify-around text-xl font-medium pl-4">
          <div className="">
            <div className=" ">
              <div className="pb-6 text-white font-bold text-4xl flex items-center justify-center">
                Join Us
              </div>
              <div class="cardf">
                <ul>
                  <li class="iso-pro">
                    <span></span>
                    <span></span>
                    <span></span>
                    <a href="">
                      <svg
                        viewBox="0 0 320 512"
                        xmlns="http://www.w3.org/2000/svg"
                        class="svg"
                      >
                        <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                      </svg>
                    </a>
                    <div class="text">Facebook</div>
                  </li>
                  <li class="iso-pro">
                    <span></span>
                    <span></span>
                    <span></span>
                    <a href="">
                      <svg
                        class="svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                      </svg>
                    </a>
                    <div class="text">Twitter</div>
                  </li>
                  <li class="iso-pro">
                    <span></span>
                    <span></span>
                    <span></span>
                    <a href="">
                      <svg
                        class="svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                      </svg>
                    </a>
                    <div class="text">Instagram</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex items-center z-50 opacity-100  justify-center gap-5 ml-80  "></div>

          <div className="border-b-2 border-solid border-[#4c2754] flex items-center justify-around boxkab"></div>
          <div className="flex items-center justify-around">
            <div className="flex items-center gap-6 py-4">
              <div>© 2024 Seize Your Opportunity, Inc. All Rights Reserved</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
