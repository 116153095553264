import React from 'react'
import Mycard from './MyCard'
import './Imagecarousel.css'
import img1 from '../images/wow.jpg'
import img2 from '../images/astro.webp'
import img3 from '../images/island.webp'
import img4 from '../images/monkey.webp'
import img5 from '../images/pexels-anjana-c-169994-674010.jpg'
import img6 from '../images/shoe.webp'
import img7 from '../images/zoo.webp'
const Imagecarousel = () => {
    let box=document.querySelector('.product-container')
    const btnpressprev = () => {
        try {
            const box = document.querySelector('.product-container');
            if (box) {
                let width = box.clientWidth;
                box.scrollLeft = box.scrollLeft - width;
                console.log(width);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const btnpressnext = () => {
        try {
            const box = document.querySelector('.product-container');
            if (box) {
                let width = box.clientWidth;
                box.scrollLeft = box.scrollLeft + width;
                console.log(width);
            }
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <div className="product-carousel">
            <button className="pre-btn" onClick={btnpressprev}><p>&lt;</p></button>
            <button className="next-btn" onClick={btnpressnext}><p>&gt;</p></button>


            <div className="product-container">
                <Mycard cardno='1' className="card-1" image={img1} />
                <Mycard cardno='2' image={img2}/>
                <Mycard cardno='3' image={img3}/>
                <Mycard cardno='4' image={img4}/>
                <Mycard cardno='5' image={img5}/>
                <Mycard cardno='6' image={img6}/>
                <Mycard cardno='7' image={img7}/>
                <Mycard cardno='8' />
                <Mycard cardno='9' />
                <Mycard cardno='10' />
                <Mycard cardno='11' />
                <Mycard cardno='12' />
                <Mycard cardno='13' />
            </div>
        </div>
    )
}

export default Imagecarousel