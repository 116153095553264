import React, { useState, useEffect, useRef } from 'react';
import './UpperBody.css';

import img1 from './image/img1.png';
import img2 from './image/img2.png';
import img3 from './image/img3.png';
import img4 from './image/img4.png';
import img5 from './image/img5.png';
// import logo from './S.png'
// import Start from './Start';
// import Component from '../Components2/Component';
// import Component from '../Components2/Component';
// import Navbar from '../Components2/Navbar';
import Navbar from './Navbar';

const images = [img1, img2, img3, img4, img5];

function UpperPart() {
  const [activeIndex, setActiveIndex] = useState(0);
  const itemsRef = useRef([]);
  const thumbnailsRef = useRef([]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     next();
  //   }, 5000);
    
  //   return () => clearInterval(interval);
  // }, [activeIndex]);

  const showSlider = (index) => {
    setActiveIndex(index);
  };

  const next = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div>
       <div className='text-white w-full fixed top-0 left-0 z-[999] bg-black/25'>
  
  <Navbar textColorClassName={'text-white'} showCustomButton={true}/>
</div>  

      <header>
        {/* <div className="logo mt-3"><img className='w-40' src={logo} alt="" /></div>
        <ul className="menu ">
          <Start/>
          
        </ul>
        <div className="search mt-3">
          <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
          </svg>
        </div> */}
        
      </header>

      <div className="slider">
        <div className="list">
          {images.map((img, index) => (
            <div
              key={index}
              className={`item ${index === activeIndex ? 'active' : ''}`}
              ref={(el) => (itemsRef.current[index] = el)}
            >
              <img src={img} alt={`Slider ${index + 1}`} />
              <div className="content flex flex-col items-center">
                <p className='text-6xl font-bold text-[#ff5757]'>SEIZE</p>
                <h2 className=''>Your Opportunity</h2>
                {/* <p>
                Web3 crowdfunding is a revolutionary approach to fundraising that leverages blockchain technology. Unlike traditional crowdfunding platforms, which rely on intermediaries, Web3 platforms offer a decentralized and transparent ecosystem. This means that transactions are directly between funders and project creators, eliminating the need for middlemen
                </p> */}
                <div className='py-2'>

                <button className='border-2 border-solid px-3 py-2'>DOCUMENTS</button>
                </div>
              </div>
              </div>
            
          ))}
        </div>

        <div className="arrows">
          <button onClick={prev} id="prev">
            <p>&lt;</p>
          </button>
          <button onClick={next} id="next">
            <p>&gt;</p>
          </button>
        </div>

        <div className="thumbnail">
          {images.map((img, index) => (
            <div
              key={index}
              className={`item ${index === activeIndex ? 'active' : ''}`}
              ref={(el) => (thumbnailsRef.current[index] = el)}
              onClick={() => showSlider(index)}
            >
              <img src={img} alt={`Thumbnail ${index + 1}`} />
              <div className="content">
                Name Slider
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UpperPart;
