import React from "react";
import Avatar from "react-avatar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const Tabsc = () => {
  return (
    <div>
      <div className="text-4xl py-10 font-bold ml-5">Name of the Admin</div>
      <div>
        <Tabs
          defaultActiveKey="profile"
          id="fill-tab-example"
          className="mb-3 bg-transparent"
          fill
        >
          <Tab eventKey="home" title="Profile">
            <div className="flex items-center justify-center">
              <div className="mt-16 mr-96">
                {" "}
                <Avatar name="pending status" size="200" round={true} />{" "}
              </div>
              <div className="flex flex-column items-center justify-center space-y-9 ml-72 mt-10">
                <div className="flex items-center justify-center gap-4 mr-20">
                  <div>
                    {" "}
                    <Avatar name="pending status" size="65" round={true} />
                  </div>
                  <div className="font-500 text-4xl">About Me</div>
                </div>
                <div className="flex flex-col items-center justify-center space-y-9 text-2xl">
                  <div>Campaigns:</div>
                  <div>Contributions:</div>
                </div>
                <div className="text-2xl font-medium border-b-2 text-gray-400 !mt-96 absolute">
                  Verifications{" "}
                </div>
                <div></div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="profile" title="Camapaigns">
            <div>
              <div className="py-9">
                <h1 className="font-semibold text-2xl ml-16">
                  Campaigns I'm On
                </h1>
              </div>
              <div className="text-wrap flex items-center justify-center ml-16 lineclass">
                No campaigns under your belt yet, but you have great ideas and
                so do the people you know. You could start a campaign today or
                team up with friends to make your ideas come to life!
              </div>
            </div>
          </Tab>
          <Tab eventKey="longer-tab" title="Contributions">
            <div>
              <div className="py-10 font-medium text-3xl ml-6">
                <h1>My Contributions</h1>
              </div>
              <div className="flex items-center justify-evenly border-b-2 border-t-2 py-6">
                <div>Date</div>
                <div>Campaign</div>
                <div>Amount Perk</div>
                <div>Visibility</div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Tabsc;
