import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import FundCard from "./FundCard";
import { loader } from "../../assets";
import Web3 from "web3";
import abi from "../../constants/abi"; // Import ABI for CampaignFactory contract
import contracts from "../../constants/contracts"; // Import contract addresses
import { useWeb3React } from "@web3-react/core";
const DisplayCampaigns = ({ title }) => {
  const navigate = useNavigate();
  const [fetchedCampaigns, setFetchedCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { account, signer, activate } = useWeb3React();
  // Initialize Web3 instance
  const web3 = new Web3(window.ethereum);

  // CampaignFactory contract instance
  const campaignFactoryAddress = contracts.Factory; // Replace with the actual contract address
  const campaignFactoryContract = new web3.eth.Contract(
    abi.Factory,
    campaignFactoryAddress
  );
  const handleNavigate = (campaign) => {
    navigate(`/upload/campaign-details/${campaign.title}`, { state: campaign });
  };

  // Fetch campaigns from the contract using getCampaignsInRange
  const fetchCampaigns = async () => {
    try {
      setIsLoading(true);

      // Set start and end indices to fetch a range of campaigns
      const startIndex = 0; // Adjust this as needed
      const endIndex = Number(
        await campaignFactoryContract.methods.totalCampaigns().call()
      );
      // console.log(startIndex, endIndex);
      // Fetch campaigns in range
      const campaigns = await campaignFactoryContract.methods
        .getCampaignsInRange(startIndex, endIndex - 1)
        .call();
      console.log(campaigns);
      // Format the fetched campaign data for display
      const formattedCampaigns = campaigns.map((campaign) => ({
        owner: campaign.creator,
        title: campaign.title,
        description: campaign.description,
        target: Number(campaign.goalAmount),
        deadline: Number(campaign.duration), // Duration in seconds
        amountCollected: Number(campaign.currentAmount),
        image: campaign.imageUrl,
      }));

      // Update state with the fetched campaigns
      setFetchedCampaigns(formattedCampaigns);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
      setIsLoading(false);
    }
  };

  // Fetch campaigns on component mount
  useEffect(() => {
    if (window.ethereum) {
      fetchCampaigns();
    }
  }, [account, window]);

  return (
    <div>
      <h1 className="font-epilogue font-semibold text-[18px] text-white text-left">
        {title} ({fetchedCampaigns.length})
      </h1>

      <div className="flex flex-wrap mt-[20px] gap-[26px]">
        {isLoading && (
          <img
            src={loader}
            alt="loader"
            className="w-[100px] h-[100px] object-contain"
          />
        )}

        {!isLoading && fetchedCampaigns.length === 0 && (
          <p className="font-epilogue font-semibold text-[14px] leading-[30px] text-[#818183]">
            You have not created any campigns yet
          </p>
        )}

        {!isLoading &&
          fetchedCampaigns.length > 0 &&
          fetchedCampaigns.map((campaign) => (
            <FundCard
              key={uuidv4()}
              {...campaign}
              handleClick={() => handleNavigate(campaign)}
            />
          ))}
      </div>
    </div>
  );
};

export default DisplayCampaigns;
