import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Web3 from "web3";
import { CountBox, CustomButton, Loader } from "../Components/components";
import { calculateBarPercentage, daysLeft } from "../utils";
import contracts from "../constants/contracts";
import abi from "../constants/abi";
import { useWeb3React } from "@web3-react/core";

const CampaignDetails = () => {
  const { state } = useLocation();
  const { account, signer, activate } = useWeb3React();
  const navigate = useNavigate();
  console.log(state);
  const [isLoading, setIsLoading] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [amount, setAmount] = useState("");
  const [donators, setDonators] = useState([]);

  const web3 = new Web3(window.ethereum);
  const campaignContract = new web3.eth.Contract(
    abi.Campaign, // The ABI for the Campaign contract
    state.address // The address of the specific Campaign contract
  );

  const fetchCampaignDetails = async () => {
    const details = await campaignContract.methods.getCampaignDetails.call();
    console.log(details);
    setCampaign({
      creator: details[0],
      goalAmount: web3.utils.fromWei(details[1], "ether"),
      currentAmount: web3.utils.fromWei(details[2], "ether"),
      remainingTime: details[3],
      isActive: details[4],
      title: details[5],
      description: details[6],
      story: details[7],
      imageUrl: details[8],
    });
  };

  useEffect(() => {
    fetchCampaignDetails();
  }, [account]);

  const handleDonate = async () => {
    setIsLoading(true);
    const amountInWei = web3.utils.toWei(amount, "ether");
    await campaignContract.methods.contribute().send({
      from: window.ethereum.selectedAddress,
      value: amountInWei,
    });

    navigate("/");
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && <Loader />}

      <div className="w-full flex md:flex-row flex-col mt-10 gap-[30px]">
        <div className="flex-1 flex-col">
          <img
            src={campaign?.imageUrl || "default_image.png"} // Use a default image if none provided
            alt="campaign"
            className="w-full h-[410px] object-cover rounded-xl"
          />
          <div className="relative w-full h-[5px] bg-[#3a3a43] mt-2">
            <div
              className="absolute h-full bg-[#4acd8d]"
              style={{
                width: `${calculateBarPercentage(
                  campaign?.goalAmount,
                  campaign?.currentAmount
                )}%`,
                maxWidth: "100%",
              }}
            ></div>
          </div>
        </div>

        <div className="flex md:w-[150px] w-full flex-wrap justify-between gap-[30px]">
          <CountBox
            title="Days Left"
            value={daysLeft(campaign?.remainingTime)}
          />
          <CountBox
            title={`Raised of ${campaign?.goalAmount} ETH`}
            value={`${campaign?.currentAmount} ETH`}
          />
          <CountBox title="Total Backers" value={donators.length} />
        </div>
      </div>

      <div className="mt-[60px] flex lg:flex-row flex-col gap-5">
        {/* Existing content */}
      </div>

      <div className="flex-1">
        <h4 className="font-epilogue font-semibold text-[18px] text-white uppercase">
          Fund
        </h4>

        <div className="mt-[20px] flex flex-col p-4 bg-[#1c1c24] rounded-[10px]">
          <input
            type="number"
            placeholder="ETH 0.1"
            step="0.01"
            className="w-full py-[10px] sm:px-[20px] px-[15px] outline-none border-[1px] border-[#3a3a43] bg-transparent font-epilogue text-white text-[18px] leading-[30px] placeholder:text-[#4b5264] rounded-[10px]"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <CustomButton
            btnType="button"
            title="Fund Campaign"
            styles="w-full bg-[#8c6dfd]"
            handleClick={handleDonate}
          />
        </div>
      </div>
    </div>
  );
};

export default CampaignDetails;
