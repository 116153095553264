import React from 'react'
import './terms.css'
import Navbar from './Navbar'

const Terms = () => {
  return (
    <div>

        <div>
            <Navbar seizeButtonColor="black"/>
        </div>
    <div className="terms-container !py-20">
      <h1 className="terms-title">Seize Terms and Conditions</h1>
      
      <div className="terms-notice">
        <h2>IMPORTANT NOTICE: AGREEMENT TO ARBITRATION</h2>
        <p>BY AGREEING TO THESE TERMS, USERS AGREE TO RESOLVE DISPUTES WITH SEIZE THROUGH BINDING ARBITRATION AND USERS WAIVE CERTAIN RIGHTS TO PARTICIPATE IN CLASS ACTIONS (AS DETAILED IN THE DISPUTE RESOLUTION, ARBITRATION AND CLASS ACTION WAIVER IN SECTION 19 BELOW).</p>
      </div>

      <div className="terms-section">
        <h2>1. Amendments</h2>
        <p>Seize may amend the Terms and Conditions at any time in its sole discretion by posting a revised version of the T&Cs. Unless stated otherwise, access to or continued use of the Services after the effective date of any revised T&Cs constitutes your acceptance of the revised Terms. If Seize makes any material change to these Terms, We will notify Users by posting a notice on our Terms and Conditions page, through the Services, by direct contact, or by other means at our disposal.</p>
      </div>

      <div className="terms-section">
        <h2>2. All User Obligations</h2>
        <p>We grant you a limited, non-exclusive, non-transferable, and revocable license to use Our Services—subject to these Terms and the following restrictions in particular:</p>
        <ul>
          <li>You are responsible for your account.</li>
          <li>Terminating your account.</li>
          <li>You must be 18 years old.</li>
          <li>Follow the law and Our policies.</li>
          <li>Be truthful.</li>
          <li>Respect the rights of others.</li>
          <li>Avoid spam and unsolicited communications.</li>
          <li>Respect the property of others.</li>
          <li>Do not engage in activities that affect the functioning of the Site.</li>
        </ul>
      </div>

      {/* Add more sections as needed */}

    </div>
</div>
  )
}

export default Terms

// const terms = () => {
    //   return (
        
    //     <div>
    //       Seize Terms and Conditions
    
    // IMPORTANT NOTICE: AGREEMENT TO ARBITRATION. BY AGREEING TO THESE TERMS, USERS AGREE TO RESOLVE DISPUTES WITH SEIZE THROUGH BINDING ARBITRATION AND USERS WAIVE CERTAIN RIGHTS TO PARTICIPATE IN CLASS ACTIONS (AS DETAILED IN THE DISPUTE RESOLUTION, ARBITRATION AND CLASS ACTION WAIVER IN SECTION 19 BELOW).
    
// Seize may amend the Terms and Conditions at any time in its sole discretion by posting a revised version of the T&Cs. Unless stated otherwise, access to or continued use of the Services after the effective date of any revised T&Cs constitutes your acceptance of the revised Terms. If Seize makes any material change to these Terms, We will notify Users by posting a notice on our Terms and Conditions page, through the Services, by direct contact, or by other means at our disposal.



// All User Obligations
// We grant you a limited, non-exclusive, non-transferable, and revocable license to use Our Services—subject to these Terms and the following restrictions in particular:

// You are responsible for your account. 
// To use many of the Services, you will need to create an account with a crypto wallet. You must provide us with accurate and complete information, and you must update your account information as needed to keep the information accurate and complete. You may not impersonate anyone else, choose a username that may offend someone, or violate any individual’s rights. You are solely responsible for maintaining the confidentiality of your account and for all activities associated with or occurring under your account. If you suspect or discover any unauthorized use of your account, you should notify Seize immediately by contacting us. We are not responsible for any loss or damage arising from your failure to comply with the foregoing requirements or as a result of use of your account with or without your knowledge.
// Terminating your account. 
// You may submit a request to terminate your account by contacting us here. This will not automatically delete User Content. We may need to retain certain information as required by law or as necessary for Our legitimate business purposes. These Terms, including our rights to User Content, survive termination of an account.
// You must be 18 years old. 
// You must be 18 years or older to sign up for an account and use Our Services. Outside of the European Economic Area (“EEA”), minors between the age of 13 and 18 years are only permitted to use Our Services through an account owned by a parent or legal guardian where the parent has provided affirmative consent and under their direct supervision. Children under 16 residing in the EEA are not permitted to access the Site or use the Services . You are responsible for any and all account activity conducted by a minor on your account.
// Follow the law and Our policies. You may not take any action that infringes or violates other people’s rights, violates the law, or breaches any contract or legal duty you may have toward any party. You may not offer Perks that are illegal, violate any Seize policies, rules, or guidelines, or violate any applicable law, statute, ordinance, or regulation. All Users must abide by Our Terms and Conditions. It is your responsibility to continuously monitor Terms and Conditions as these may be updated from time-to-time at Seize’s sole discretion.
// Be truthful. Do not post information you know is false, misleading, or inaccurate. Do not do anything deceptive or fraudulent.
// Respect the rights of others. 
// You may not threaten, abuse, harass, defame, or engage in behavior that is libelous, tortious, obscene, profane, or invasive of another’s privacy.
// Avoid spam and unsolicited communications. You may not distribute unsolicited or unauthorized advertising or promotional material, or any junk mail, spam, or chain letters. Do not run mail lists, listservs, or any kind of autoresponder or spam on or through the Site.
// Respect the property of others. Do not distribute software viruses or any other programs designed to interfere with (1) the proper function of any software, hardware, or equipment on the Site or (2) the use of the Site by any other User.
// Do not engage in activities that affect the functioning of the Site. You may not bypass any measures that We have put in place to secure Our Site or Services, take actions to gain unauthorized access to any system, data, passwords, or other Seize or User information or Services, reverse engineer or take apart any aspect of the Services to access any underlying information, or use any kind of software to “crawl” or “spider” any part of the Site.
// Fees. 
// There are no fees for creating an account on the Site. Campaign Owners are charged a Platform Fee as a percentage of the Contributions they raise and will be required to make the first contribution to their own campaign in the amount of $15 as a way of deterring spam account creations. This method of creating a blockchain account will allow us to use blockchain scraper bots to track the transaction that come to our site ensuring where not under attack by a malicious person(s). In addition, Campaign Owners are charged a transaction fee by the blockchain they use to transfer the funds.  

// Right to terminate. 
// Should you violate any of the Terms of Use, We reserve the right to terminate, suspend, or take any other action related to your account at Our sole discretion. You may not access Our Site or Services if We have prohibited you from doing so.

// Seizers Obligations
// When you create a Campaign on the Site and ask for Contributions, you understand that you are entering into separate legal agreements with both Seize and with your Contributors, and the following rules apply (in addition to all other Terms and Additional Policies).
// Make good faith efforts to fulfill the Perks associated with Your Campaign in the timeframe that You communicate to Contributors, and prioritize fulfillment of those Perks.

// Be responsive. 
// Respond promptly and truthfully to all questions posed in comments, messages, or updates including any questions or requests Seize makes to verify ability to fulfill. If unresponsive or if a dispute arises between You and your Backers, We may provide your name, legal mailing address and email address to your Contributors, or in the instances where the law requires it.
// Updates. 
// Provide substantive and quality updates at least once a week to Contributors. Immediately notify Contributors if there are obstacles or delays.
// If you have received the Contributions from your Campaign, but are unable to deliver Perks, issue refunds to Contributors.
// Comply with Laws. 
// Comply with all applicable laws and regulations in statements concerning Your Campaign and Perk(s), the use of Contributions and delivery of Perks.

// Be Truthful and Transparent. 
// Campaign Team Members should be ready, willing, and able to substantiate claims your Campaign makes, including but not limited to product features and capabilities, the stage of product development, and timelines for delivery with verifiable evidence if requested. If you are unable to uphold claims, Seize may terminate your account, withhold funds, provide your name, mailing and email address, or take other actions to enforce its rights under this Agreement and applicable law.
// Respect Privacy. 
// When you use the Services by creating a Campaign, you may receive information about Users that have Contributed to your Campaign, including personally identifiable information (“PII”), such as names, e-mail addresses, and physical addresses. This information is provided to you purely for the purpose of fulfilling the Perks for the applicable Campaign and other Services, and may not be used or disclosed for any other purposes, including cross-promotional marketing of any other products including your own other products, without separate, verifiable consent from the User obtained independently from Seize or the Service. You will maintain, and be required to produce if requested, records of all such verifiable consent.
// Comply with EEA Data Controller Obligations. As a Campaign Owner, you will independently fulfill all duties required of data controllers under applicable EEA data protection law (such law shall include, but not be limited to, the EU General Data Protection Regulation (Regulation (EU) 2016/679) (“GDPR”); (iii) the EU e-Privacy Directive (Directive 2002/58/EC), including subsequent variations, such as the Regulation of the European Parliament and of the Council concerning the respect for private life and the protection of personal data in electronic communications and repealing Directive 2002/58/EC (“ePrivacy Regulation”), if enacted); and the current Standard Contractual Clauses (“SCCs”) for Co-Controllers for international data transfers. Data transfers are in accordance with the SCCs and are detailed in the Privacy Policy.
// Your data controller duties include:
// Determining and qualifying the necessary legal basis or bases for your collection or processing of personal information;
// Managing and reporting security incidents;
// Transfers;
// Entering into contracts with only with data processors that provide adequate protections for personal data and including appropriate contractual language;
// Maintaining records of your data processing activities;
// Conducting any required data protection impact assessments; and
// Implementing and maintaining all measures required pursuant to Article 32 of the GDPR.
// Respond to EEA Data Subject Requests. You and Seize will have an independent obligation to respond to any requests received by either of us from EEA data subjects regarding personal data, including without limitation, those set forth in Chapter III of the GDPR. EEA data subjects may include Contributors or any other party whose information you obtained in relation to your Campaign. The party that receives the data request is responsible for responding to the request. Campaign Owner and Seize shall reasonably cooperate and assist each other with these requests, to the extent that is legally permissible.
// Comply with the CCPA as a Business. 
// As a Campaign Owner, you will independently fulfill all duties required of “businesses” under the California Consumer Privacy Act (“CCPA”), including its implementing regulations. As a “business” under the CCPA, you will, without limitation: (1) provide notices to consumers as required by the CCPA, including providing a privacy policy and a notice of financial incentives, as applicable; (2) respond and honor access and deletion requests of consumers; (3) comply with all CCPA requirements related to the “sale” of personal information, as that term is defined in the statute; (4) implement reasonable security as required by the CCPA; (5) maintain records as required by the CCPA, including records related to your receipt and responses to consumer access and deletion requests; and (6) enter into appropriate contracts with vendors where required by the statute. For clarity, the party that receives a consumer request related to the CCPA is responsible for responding to the request. Campaign Owner and Seize shall reasonably cooperate and assist each other with these requests, to the extent that is legally permissible.
// Platform Fees. 
// Campaign Owners are charged a Platform Fee as a portion of the Contributions they raise (the "Platform Fees"). The Platform Fees are effective on the date that the Platform Fees are posted and may be updated from time to time. Platform Fees will be charged at the then-current rate.
// Taxes.
// Taxing authorities may classify Contributions as taxable income to the Campaign Owner and any beneficiary who will receive funds directly from the applicable Campaign. Seize may ask for the tax identification number (TIN) of Campaign Owners and any beneficiaries so that We may report taxable income to the relevant taxing authorities. Seize will provide Campaign Owners with a tax document if required by the relevant taxing authorities. Seize is unable to provide any User or third party with tax advice and suggests that such Users or third parties consult with tax advisors of their choice. The shipment of Perks to Contributors may expose Campaign Owners and Contributors to sales and use tax obligations.
// Respond to Requests from Seize for Information about Your Campaign. Seize does not guarantee any Campaigns or undertake the duty to investigate or moderate any statements made by a Campaign Owner (see section 4 below). However, Seize may, but is not required to and undertakes no obligation to, request information from Campaign Owners to investigate possible breaches of this Agreement. The information Seize may request includes, but is not limited to, information to support Campaign statements, evidence that the Campaign Owner is taking actions to deliver promised products, productions and shipping timelines and information, source documentation to substantiate product claims and Campaign promises, product prototypes, and personal information to confirm the identity of Campaign Owners, vendors, and suppliers. You agree that Seize may employ third parties to assess the documents and your Campaign. You agree to provide this information to Seize upon request. Failure to provide any of the requested information within 14 days, or a longer time provided by Seize in writing, shall be deemed a material breach of this Agreement.
// Seize Reserves the Right to Withhold Funds. 
// Campaign Owners are not guaranteed to receive Contributions made to a Campaign, even after the Campaign has ended and the fundraising goal has been reached. There may be a delay between the end of a Campaign and Our remittance of Contributions to a Campaign Owner for a number of reasons including, but not limited to, refunds, chargeback disputes, fraud, Terms or other policy violations, or any other situation. For example, Seize may withhold Contributions made to a Campaign if the Campaign Owner violates any Terms (as determined by Seize in its sole discretion). Additionally, Seize may withhold funds pending the receipt and evaluation of information requested through paragraph (j) above or during the pendency of any investigation Seize may undertake into your account. If you do not provide information within the requested time or are not able to substantiate claims made in your Campaign, Seize may terminate your Campaign and account and avail itself of any remedies available to it under this Agreement or applicable law. Seize may also withhold funds from Campaign Owners for any other breach of this Agreement. Seize may also seek reimbursement from a Campaign Owner by any other lawful means, including by using third-party collection services.
// Refunds. 
// Seize offers a limited refund to Contributors in accordance with Our Refund Policy. Refunds outside of Our Refund Policy must be handled by the Campaign Owner, and Seize has no obligation to provide any refunds or become involved with any dispute between a Campaign Owner and Contributor. Seize reserves the right to terminate User Accounts and remove Campaigns for any abuse of the Refund Policy. Seize reserves its right to issue refunds at its own discretion outside of the Refund Policy.
// Please remember that as a Campaign Owner, you are solely responsible for fulfilling the obligations of your Campaign and delivering Perks. If you are unable to perform on this, or any of your other legal obligations, you may be subject to legal action by Contributors. Your legal mailing address and contact information may be shared with Contributors seeking legal action.

// Backing a Campaign
// As a Contributor, when you make a Contribution to a Campaign, you understand that the following rules and Terms apply (in addition to all other Terms, including Additional Policies).

// Voluntary Contributions. All Contributions are made voluntarily and at the sole discretion and risk of the Contributor.
// Seize Does Not Guarantee that Campaigns Will Succeed or that Perks Will Be Delivered or Deemed Satisfactory. By contributing to a Campaign, Contributors are supporting an idea, project, or cause they care about and want to help make happen. Like anyone getting in on an early-stage project, Contributors accept the risk that the Campaign may experience changes, delays, and unforeseen challenges, or that a Campaign, and its Perks, might not come to fruition. Seize does not guarantee or represent that Contributions will be used as promised, that Campaign Owners will deliver Perks, or that the Campaign will achieve its goals. Seize undertakes no duty to investigate claims made by Campaign Owners. Seize also does not endorse, guarantee, make representations, or provide warranties regarding the quality, safety, morality or legality of any Campaign, Perk or Contribution, or the truth or accuracy of any User Content posted on the Services. The date to deliver a Perk is an estimate by the Campaign Owner (not Seize), and there is no guarantee that the Campaign Owner will fulfill and deliver the Perk by that date. No transfer of title to tangible personal property occurs through Seize.
// Limited Refund. 
// Seize offers a limited refund to Contributors, subject to the Refund Policy, only where the Campaign has not ended, the Contributor has not received a Perk (as confirmed by Seize), and the Contribution has not been sent to the Campaign Owner. Seize does not offer refunds outside Our Refund Policy, but may do so under our sole discretion. Where refunds are not available under the Refund Policy, a Contributor may contact the Campaign Owner directly to request a refund. Seize has no obligation to participate or become involved in any dispute between Contributor and Campaign Owner concerning a refund.
// Credit Card Charges. 
// When you Contribute to a Campaign, your card will be charged the amount of the Contribution after you confirm and submit your payment information.
// Communications Between Campaign Owners and Contributors. Once you have made a Contribution to a Campaign, the Campaign Owners may need to contact you to obtain additional information such as shipping address, specific preferences for a Perk (for example, color or size of a t-shirt), or may ask for feedback on the Campaign or the Perk. In some instances, to receive the Perk, you may need to provide requested information within a specific time frame to receive the Perk. Not doing so may result in your forfeiting the Perk. Campaign Owners should not ask for information that is not required to fulfill a Perk, including personal information such as Social Security numbers or credit card/banking information. Please contact us here if you receive a request for information that appears to be excessive.
// Taxes are your responsibility. 
// Contributors bear the sole responsibility for determining how to treat their Contributions and receipt of any Perks for tax purposes. Seize does not offer any advice or take any responsibility for how Contributors treat their Contributions or for any financial consequences arising out of such treatment.
// Seize’s Role
// We do not endorse any User Content. Seize provides the platform for creation of Campaigns and interaction of Users. We do not screen Campaigns or endorse any User Content on Our site. Likewise, Seize does not undertake any duty to investigate or guarantee the truthfulness of any claims made by Campaign Owners. You should evaluate a Campaign’s statements before choosing to back the Campaign.

// We may remove User Content or cancel Campaigns and refund Contributions in Our discretion. Seize may remove User Content that violates Our Terms of Use in Our sole discretion. Removing User Content, terminating an account, or canceling Campaigns and refunding Contributions are not actions We take lightly, and We may take a while to investigate and determine if such actions are warranted. It is in Our sole discretion whether to discuss the reasons for taking action against any User Content or account.
// We may require you to provide information about your Campaign before disbursing funds. Seize may, but is not required to, request information from you regarding Campaigns. See section 3(j) above for more information.

// We do not guarantee Perks or Refunds. Campaign Owners bear sole responsibility for the delivery of Perks and for the offering of any refunds outside Our Refund Policy.
// We do not provide tax or legal advice to Users. Users bear sole responsibility for determining how the applicable laws apply to running a Campaign, Contributing to a Campaign, or utilizing any of the Services.

// We do not become involved in legal disputes between Users. Seize bears no liability, and has no obligation to investigate, participate, or become involved, in any dispute between Users, including between Contributors and Campaign Owners.

// You are responsible for your Campaigns, even if you engage Our help. Seize may offer additional services, including helping with drafting Campaign Content, assisting with the design of Campaigns, and referring vendors and other service providers to Campaign Owners. Any such services will be subject to a separate agreement between the Campaign Owner and Seize. Even where We help with your Campaign, Campaign Owners bear sole responsibility for the Campaign and must comply with all obligations in the Terms as the only responsible party for the Campaign.
// Our Intellectual Property
// Seize Intellectual Property. Seize’s Services, Content and Marks, are legally protected in a number of ways, including pursuant to copyright, trademark, service marks, patent, trade secrets, and other U.S. and international intellectual-property laws. You agree to respect all copyright and other legal notices, information, and restrictions contained in any Seize Content, Services, or Marks accessed through the Site or the Services. You agree not to change, translate, or otherwise create derivative works of the Services.

// Limited User Rights; License to Seize Content. 
// Seize grants you a limited license (that is temporary, non-exclusive, non-sublicensable, and non-transferrable) to access and use User Content and Seize Content solely for use of the Services in accordance with these Terms. You may not reproduce, redistribute, transmit, assign, sell, broadcast, rent, share, lend, modify, adapt, edit, create derivative works of, license, or otherwise transfer or use any User Content or Seize Content unless We give you express written permission to do so. We reserve the right to revoke this limited license to access and use User Content and Seize Content at any time and in our sole discretion.
// Seize Trademark Policy. You may use the Seize Marks only in accordance with Our Trademark Policy.

// Your Intellectual Property
// Your User Content remains your property. When you submit User Content to the Site or via the Services, you agree to the following terms:

// You allow us to use your User Content. 
// You grant us a worldwide, non-exclusive, perpetual, irrevocable, royalty-free, sublicensable, and transferable right to use, exercise, commercialize, and exploit the copyright, publicity, trademark, and database rights with respect to your User Content.

// We can make changes to or delete your User Content. 
// You grant us the right to make changes, edits, modifications, translations, formatting, or delete your User Content.
// You have all legal rights to your User Content. 
// You represent and warrant that: (1) you have all licenses, rights, consents, and permissions necessary to grant the rights set forth in these Terms to Seize with respect to your User Content; (2) your User Content does not and will not infringe any third party's intellectual property rights, proprietary rights, privacy rights, confidentiality, rights of publicity or otherwise violate these Terms or applicable law; and (3) Seize does not need to obtain any licenses, rights, consents, or permissions from, or make any payments to, any third party for any use of your User Content, or have any liability to a User or any other party as a result of Our use or exploitation of your User Content.
// How to report Copyright and covered other Intellectual Property issues
// We take intellectual property rights very seriously. We comply with intellectual property laws and industry best practices to maintain the integrity of Our Site and Services. The Digital Millennium Copyright Act (“DMCA”) sets out the procedure to report notices of alleged copyright infringement. Seize also maintains policies concerning claims of trademark and patent infringement. We will respond to notices of alleged infringement in accordance with the law, as set forth in Our Intellectual Property Policy here. We reserve the right, in our sole discretion, to delete or disable User Content that has been alleged to be infringing, as well as to terminate User accounts associated with infringers.

// For further details, or to submit a claim of copyright infringement, please visit Our Intellectual Property Policy here. We will only respond to notices of alleged infringement submitted in compliance with Our Intellectual Property Policy.

// Seize’s Rights 
// Concerning User Accounts
// Termination
// We reserve the right to monitor, terminate, suspend, or delete any User Account at any time in Our sole discretion. We have no obligation to comment on any reasons for account monitoring, termination, suspension, or deletion.
// We have no liability to you or third parties for any use of the Services associated with your account or Campaign. Upon suspension or termination of your account, you must cease all use of the Services and User Content. Your representations and warranties shall survive termination.
// Seize reserves the right to suspend or terminate a Campaign if it does not comply with this agreement or in its sole discretion. In the event of termination, Seize may refund any and all Campaign funds received by a Campaign Owner to backers and may seek reimbursement from you for any funds you have already received from Seize. Seize may also avail itself of any of the remedies provided for in this Agreement and any remedies available to enforce its rights under this Agreement or applicable law.
// Payment Services
// Credit card payment processing services for Campaign Owners on Seize are provided by Stripe, Inc. ("Stripe") and are subject to the Stripe Connected Account Agreement, which includes the Stripe Terms of Service (collectively, the "Stripe Services Agreement"). As a Campaign Owner, you agree to be bound by the Stripe Services Agreement, as may be modified by Stripe from time to time. As a condition to Seize enabling credit card payment processing services through Stripe, you agree to provide Seize accurate and complete information about yourself and your business, and you authorize Seize to share any such information with Stripe, as well as transaction information related to your use of the Stripe payment processing services. In all cases, standard credit card or other third-party processing fees apply in addition to any Platform Fees. We are not responsible for the performance of any third-party credit card processing or third-party payment services.

// System Outages and Maintenance
// The Site or Services may be unavailable for scheduled maintenance and other reasons, including unplanned outages and other malfunctions. We are not responsible if the Site or Services are unavailable, or if you lose any data, information, or User Content for any reason.

// Unsolicited Idea Submissions
// We appreciate hearing from Our Users and welcome their comments or suggestions. But ideas that you submit may be similar or identical to internal submissions or submissions received from another User or third party. When We refer to a “submission” in this paragraph, We mean: any submission, comment, or suggestion (including, but not limited to, ideas, products, or services, know-how, concepts, suggested changes, additions, or improvements) sent to us via the Site or in any other manner about an existing product, service, or feature on the Seize platform (“Unsolicited Idea Submission”). If you send us an Unsolicited Idea Submission, you agree as follows:

// All Unsolicited Idea Submissions are non-confidential and non-proprietary and will be treated as such.
// By submitting an Unsolicited Idea Submission, you hereby grant Seize a perpetual, irrevocable, worldwide, non-exclusive, royalty- free, sublicensable and transferable license to use, reproduce, distribute, sell, exploit, prepare derivative works of and display the Unsolicited Idea Submission, including, without limitation, in connection with the Site or Services, and for promoting and redistributing part or all of the Unsolicited Idea Submission (and derivative works thereof) in any media formats and through any media channels whether now known or hereafter developed, without payment or accounting for the Unsolicited Idea Submission and,
// We are under no obligation to evaluate, review, or use any Unsolicited Idea Submission.
// Indemnity
// You agree to defend, indemnify and hold harmless Seize, Our subsidiaries and affiliated companies, and Our officers, directors, employees, partners, contractors, representatives, agents, and third party providers from and against any and all claims, causes of action, damages, obligations, losses, liabilities, costs or debt, and expenses (including reasonable attorneys' fees and costs) and all amounts paid in settlement arising from or relating to, use or misuse of the Services, breach or these Terms or violation of any applicable laws. We reserve the right, in Our sole discretion and at Our own expense, to assume the exclusive defense and control of any matter for which you have agreed to indemnify us and you agree to assist and cooperate with us as reasonably required in the defense or settlement of any such matters.

// No Warranty
// Seize HAS NO FIDUCIARY DUTY TO ANY USER. THE SERVICES, Seize CONTENT, USER CONTENT, AND ANY OTHER MATERIALS MADE AVAILABLE OR THROUGH THE USE OF THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT OR COURSE OF PERFORMANCE. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, Seize AND ITS AFFILIATES, LICENSORS, SUPPLIERS, ADVERTISERS, SPONSORS AND AGENTS, DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, IMPLIED WARRANTIES OF TITLE, NON- INFRINGEMENT, ACCURACY, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE. Seize AND ITS AFFILIATES, LICENSORS, SUPPLIERS, ADVERTISERS, SPONSORS AND AGENTS DO NOT WARRANT THAT YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE OR SECURE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES OR THE SERVER(S) ON WHICH THE SERVICES ARE HOSTED, OR ANY SERVICES AVAILABLE ON ANY THIRD PARTY PLATFORM ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. NO OPINION, ADVICE OR STATEMENT OF Seize OR ITS AFFILIATES, LICENSORS, SUPPLIERS, ADVERTISERS, SPONSORS, AGENTS, MEMBERS OR VISITORS, WHETHER MADE THROUGH THE USE OF THE SERVICES, OR ON THIRD-PARTY PLATFORMS OR OTHERWISE, SHALL CREATE ANY WARRANTY. YOUR USE OF THE SERVICES, INCLUDING WITHOUT LIMITATION ANY SERVICES PROVIDED ON ANY THIRD-PARTY PLATFORM, ARE ENTIRELY AT YOUR OWN RISK.

// Disclaimer of Liability
// Use the Services at your own risk. We make no representations about the quality, safety, morality or legality of any Campaign, Perk or Contribution or the truth or accuracy of User Content posted on the Services. We do not guarantee the fulfillment or the performance of any Perks, or that Contributions will be used as described in the Campaign. We do not control or endorse User Content posted on the Site or in any Services and, as a result, do not guarantee in any manner the reliability, validity, accuracy or truthfulness of such User Content. Seize is not liable for any damages or losses related to your use of the Services. We do not become involved in disputes between Users, or between Users and any third party relating to the use of the Services.
// You release us from all claims. When you use the Services, you release Seize from claims, damages, and demands of every kind - known and unknown, suspected or unsuspected, disclosed or undisclosed - arising out of or related to the Services. All Content that you access or use through the Services is entirely at your own risk and you solely are responsible for any resulting damage or loss to any party.
// YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE SERVICES AND USER CONTENT, AND ANY CONTACT YOU HAVE WITH OTHER USERS OR THIRD PARTIES, WHETHER IN PERSON OR ONLINE, REMAINS WITH YOU. NEITHER Seize NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES WILL BE LIABLE (WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT Seize HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE) FOR: (A) ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS OF GOODWILL; (B) SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE; (C) THE COST OF SUBSTITUTE PRODUCTS OR SERVICES; (D) ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS; (E) THE USE OF OR INABILITY TO USE THE; (F) ANY COMMUNICATIONS OR OTHER INTERACTIONS WITH OTHER USERS OR OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SERVICES; OR (G) YOUR CAMPAIGNS OR CONTRIBUTIONS.

// IN NO EVENT SHALL THE TOTAL, AGGREGATE LIABILITY OF Seize AND ITS AFFILIATES AND SUBSIDIARIES, AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS, ARISING FROM OR RELATING TO THE TERMS, SERVICES, OR USER CONTENT, OR FROM THE USE OF OR INABILITY TO USE THE SERVICES EXCEED THE TOTAL AMOUNT OF FEES ACTUALLY PAID TO Seize BY YOU HEREUNDER, OR ONE HUNDRED US DOLLARS IF NO SUCH PAYMENTS HAVE BEEN MADE.

// THE LIMITATION OF LIABILITY DESCRIBED ABOVE SHALL APPLY FULLY TO RESIDENTS OF NEW JERSEY.

// Export Compliance
// You agree to comply with all applicable import, re-import, export, and re-export control laws and regulations, including the Export Administration Regulations, the International Traffic in Arms Regulations, and country-specific economic sanctions programs implemented by the Office of Foreign Assets Control. For clarity, you are solely responsible for compliance related to the manner in which you use any third-party products funded through the website. Further, pursuant to 15 C.F.R. § 758.6(a)(1), you are hereby advised that for any items that are physically shipped to you, the items are controlled by the U.S. Government and authorized for export only to the country of ultimate destination for use by the ultimate consignee or end-user(s) herein identified. They may not be resold, transferred, or otherwise disposed of, to any other country or to any person other than the authorized ultimate consignee or end-user(s), either in their original form or after being incorporated into other items, without first obtaining approval from the U.S. government, or as otherwise authorized by U.S. law and regulations.

// You agree to indemnify Seize for any penalties, fines, attorneys’ fees and other amounts that may be incurred by Seize that arise out of or are related to your failure to comply with this Section.

// Waiver and Release (for CA Residents)
// California residents must, as a condition of this Agreement, waive the applicability of California Civil Code Section 1542 for unknown claims which states: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY." You waive this section of the California Civil Code and any similar provision in law, regulation or code that has the same effect or intent as the foregoing release.

// Legal Disputes Not Subject to Arbitration Will Be Handled In San Francisco, CA and Subject to California Law.
// Seize is based in San Francisco, California. For any action not subject to arbitration, you and Seize agree to submit to the personal jurisdiction of a state court located in San Francisco, CA or the United States District Court for the Northern District of California. The Terms and the relationship between you and Seize shall be governed in all respects by the laws of the State of California, without regard to its conflict of law provisions.

// Legal Disputes Subject to Arbitration, Dispute Resolution, and Class Action Waiver
// PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.

// You and Seize agree that this arbitration undertaking is made pursuant to or in connection with a transaction involving interstate commerce, and shall be governed by and construed and interpreted in accordance with the Federal Arbitration Act at 9 U.S.C. Section 1, et seq. Terms affect interstate commerce and that the Federal Arbitration Act governs the interpretation and enforcement of these arbitration provisions.

// This Section is intended to be interpreted broadly and governs any and all disputes between us, including but not limited to claims arising out of or relating to any aspect of the relationship between us, whether based in contract, tort, statute, fraud, misrepresentation or any other legal theory; claims that arose before these Terms or any prior agreement (including, but not limited to, claims related to interactions between users); and claims that may arise after the termination of these Terms. The only disputes excluded from this Section are the litigation of certain intellectual property and small court claims, as provided below.

// By agreeing to these Terms, you agree to resolve any and all disputes with Seize as follows:

// Pre-Arbitration Dispute Resolution : For any and all disputes, claims, or controversies you may have against Seize (“Disputes”), whether pursued in court or arbitration, you must first give us an opportunity to resolve the Dispute informally by contacting us through this email address: support@Seize.com with the following information: (1) your name, (2) your address, (3) a written description of your claim, and (4) a description of the specific relief you seek. If we do not resolve the Dispute within forty-five (45) days after receiving your notification, then you may pursue resolution of the Dispute in arbitration. You may pursue your Dispute in a court only under the circumstances described below.

// Arbitration Procedures :

// If the Dispute has not been resolved and is not subject to the exclusions provided above, then either party may initiate binding arbitration as the sole means to resolve all Disputes, subject to the terms set forth below.

// All Disputes shall be resolved before a single arbitrator in accordance with the JAMS Streamlined Arbitration Rules and Procedures for claims that do not exceed $250,000 and the JAMS Comprehensive Arbitration Rules and Procedures for claims exceeding $250,000, in each case applying the rules and procedures in effect at the time the arbitration is initiated, excluding any rules or procedures governing or permitting class actions. You can find the JAMS rules and procedures here at this link.

// To start an arbitration with JAMS, you must do the following: (1) write a Demand for Arbitration that includes a description of the claim and the amount of damages you seek to recover (you may find a copy of a Demand for Arbitration at www.jamsadr.com); (2) send three copies of the Demand for Arbitration, plus the appropriate filing fee, to JAMS, 2 Embarcadero Center, Suite 1500, San Francisco, CA 94111; and (3) send three copies of the Demand for Arbitration to Seize at Corporation Service Company, 2710 Gateway Oaks Drive, Suite 150N, Sacramento, California 95833-3502, ATTN: Seize, Inc. - Arbitration Demand.

// You will be required to pay $250 to initiate an arbitration against us. If the arbitrator finds the arbitration to be non-frivolous, Seize will pay all other fees invoiced by JAMS, including filing fees and arbitrator and hearing expenses. You are responsible for your own attorneys' fees unless the arbitration rules and/or applicable law provide otherwise.

// Location of Arbitration: If you live in the United States, you may initiate and litigate the arbitration in your hometown area or through the JAMS office located in San Francisco, California. If you live outside the United States, you must initiate and litigate the arbitration through the JAMS office located in San Francisco, California. Either party may ask that the arbitration including the hearings, arguments, and all conferences be conducted telephonically or by video conference (e.g., Skype).

// The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve all disputes arising out of or relating to the interpretation, applicability, enforceability, or formation of these Terms, Additional Policies, or the Privacy Policy, including but not limited to any claim that all or any part of these Terms, Additional Policies, or Privacy Policy is void or voidable, whether a claim is subject to arbitration, or the question of waiver by litigation conduct. The arbitrator shall be empowered to grant whatever relief would be available in a court under law or in equity. The arbitrator's award shall be written and shall be binding on the parties and may be entered in any court with jurisdiction.

// The parties understand that, absent this mandatory arbitration provision, they would have the right to sue in court and have a jury trial. They further understand that, in some instances, the costs of arbitration could exceed the costs of litigation and the right to discovery may be more limited in arbitration than in court.

// CLASS ACTION WAIVER: The parties agree that the arbitration shall be conducted in their individual capacities only and not as a class action or other representative action, and the parties expressly waive their right to file a class action or seek relief on a class basis. THIS MEANS THAT YOU AND Seize AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A LEAD OR REPRESENTATIVE CLASS PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. HOWEVER, THE PARTIES EXPRESSLY AGREE THAT SEPARATE INDIVIDUAL ARBITRATIONS SHALL BE CONSOLIDATED, UPON REQUEST BY EITHER PARTY, IF SUCH SEPARATE INDIVIDUAL ARBITRATIONS RELATE TO OR ARISE OUT OF THE SAME OR SUBSTANTIALLY THE SAME TRANSACTION(S) OR OCCURRENCE(S). CONSOLIDATION MEANS THAT ANY AND ALL SEPARATELY INITIATED ARBITRATIONS THAT RELATE TO OR ARISE OUT OF THE SAME OR SUBSTANTIALLY THE SAME TRANSACTION(S) OR OCCURRENCE(S) ARE ALL HEARD BY A SINGLE ARBITRATOR IN A SINGLE ARBITRATION. IF EITHER PARTY REQUESTS CONSOLIDATION OF MULTIPLE ARBITRATIONS, THEN THE PARTIES TO THE CONSOLIDATED CASE(S) WILL BE DEEMED TO HAVE WAIVED THEIR RIGHT TO DESIGNATE AN ARBITRATOR AS WELL AS ANY CONTRACTUAL PROVISION WITH RESPECT TO THE SITE OF THE ARBITRATION. THE FOLLOWING FACTORS MAY BE CONSIDERED IN REQUESTING A CONSOLIDATION OF ARBITRATIONS:

// The arbitrations have a common question of law or fact;
// The issues in the multiple arbitrations are largely identical;
// There are common claims, disputes, defenses, and relationships between or among the parties in the different arbitrations;
// One or more parties is named in multiple pending arbitrations;
// The arbitrations relate to the same campaign featured on Seize’s platform;
// Consolidation will save time and resources;
// One party will be seriously prejudiced by having multiple arbitrations heard separately.
// By agreeing to these Terms, you are waiving your right to trial by jury or to participate in a class action. We are also waiving these rights.

// Exclusions from Arbitration: Intellectual Property and Small Claims Court Claims: Notwithstanding the parties' agreement to resolve Disputes through arbitration, either party may (1) bring enforcement actions, validity determinations or claims arising from or relating to misuse, infringement, or misappropriation of intellectual property theft, piracy or unauthorized use of intellectual property in state or federal court or in the U.S. Patent and Trademark Office to protect its intellectual property rights ("intellectual property rights" means patents, copyrights, moral rights, trademarks, and trade secrets, but not privacy or publicity rights); and/or (2) seek relief in a small claims court for disputes or claims within the scope of that court's jurisdiction.

// 30-Day Right to Opt-Out: You have the right to opt out and not be bound by the arbitration and class action waiver provisions set forth above. You can opt out of the arbitration and class action waiver provisions set forth above by sending an email from your registered email address on Seize to us here with the subject line, "ARBITRATION AND CLASS ACTION WAIVER OPT-OUT." To opt-out, you must send the email to Seize with the required language within thirty (30) days of the later of the following: (i) of your creation of an Seize account or your first transaction with Seize, whichever is earlier; or (ii) the date of notice from Seize of a material change to this Section via email to you. To be clear, if you opt out of the arbitration and class action waiver provision, you will be able to pursue any potential claims in a court of law and can participate in a class action or other class proceeding if you so desire. If you do not opt out properly, you agree to arbitrate. If you opt out of the arbitration and class action waiver provisions, we will not be subject to them either with respect to any disputes with you, meaning Seize can also litigate in a court of law and be subject to class action litigation.

// Severability: If a court or arbitrator decides that any portion of this Section regarding Dispute Resolution, Arbitration and Class Action Waiver is invalid or unenforceable, then the portion shall be severed from the Terms and/or deemed modified, only to the extent necessary to make it lawful. Such invalidity shall not affect the enforceability of any other provisions of the Terms that are not invalid or unenforceable. To affect the modification of the portion, the portion shall be deemed deleted, added to, and/or rewritten, whichever shall most fully preserve the intentions of the parties as originally expressed herein.

// The Terms and the relationship between you and Seize shall be governed in all respects by all applicable laws, without regard to its conflict of law provisions. This “Dispute Resolution, Arbitration and Class Action Waiver” section shall survive any termination of your account, these Terms, or the Services.

// Definitions
// "Campaign Owners" or "Seizers" are those Users who raise funds through the Site and Services;
// "Campaigns" are Campaign Owners’ fundraising campaigns through the Services;
// "Content" refers to all software, technology, designs, materials, information, communications, text, graphics, links, electronic art, animations, illustrations, artwork, audio clips, video clips, photos, images, reviews, ideas, and other data or copyrightable materials or Content, including the selection and arrangements thereof offered through the Services;
// "Contributors" or "Backers" refers to those Users contributing funds to Campaigns;
// "Contributions" refers to funds donated to Campaigns by Contributors;
// "DMCA" refers to the Digital Millennium Copyright Act of 1998, including any amendments;
// "Seize, " "We, " "Our, " or "Us" refers to Seize, Inc., a Delaware corporation, together with its parents, subsidiaries, affiliates, agents, representatives, consultants, employees, officers, and directors;
// "Seize Content" refers to Content provided by Seize to Users in connection with the Services, including, without limitation, the software, the products and the site;
// "Infringement" refers to the unauthorized or not permitted use of copyrighted material or other intellectual property rights;
// "Marks" refers to the trademarks, service marks, and logos used and displayed throughout the Services or in any or in any Seize Content;
// "Perks" refers to the gifts or rewards in the form of tangible items or intangible services offered by Campaign Owners to Contributors;
// "PII" refers to personally-identifiable information, as that term is defined under all applicable laws;
// "Privacy Policy" refers to Seize’s Privacy Policy, found at https://www.Seize.com/about/privacy and incorporated herein by reference;
// "Services" refers to the Site, mobile applications or connected applications, other offerings and services provided on the Site;
// "Site" refers to the Seize website(s);
// "Terms" or "Terms of Use" refers the Terms of Use, Additional Policies, Seize’s Privacy Policy, all applicable laws, and all conditions or policies referenced here;
// "User, " "You,” Seizer/s, " or " Your" refers to Campaign Owners, Contributors or any other visitor to the Site or Users of the Services, either individually or collectively;
// "User Content" refers to Content uploaded, transmitted or posted to the Services by a User, including User Content in a Campaign.
// Full Agreement Between You and Us
// These Terms are the entire agreement between You and Seize with respect to the Services. They supersede all other communications and proposals (whether oral, written, or electronic) between you and Seize with respect to the Services and govern our relationship. If any provision of these Terms are deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect. Seize's failure to assert any right or provision under this Agreement shall not constitute a waiver of such right or provision.

// Miscellaneous
// We may modify or discontinue the Services at any time, in our sole discretion. You agree that, except as otherwise expressly provided in these Terms, there shall be no third-party beneficiaries to these Terms. No waiver of any provision of these Terms shall be deemed a further or continuing waiver of such term or any other term, and Seize’s failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision. You agree that regardless of any statute or law to the contrary, any claim arising out of or related to the Services must commence within one (1) year after the cause of action accrues. Otherwise, such cause of action is permanently barred.


//     </div>
//   )
// }

// export default terms
 