import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import Web3 from "web3";
import { money } from "../assets";
import { CustomButton, FormField, Loader } from "../Components/components";
import { useWeb3React } from "@web3-react/core";
// import { checkIfImage } from "../src/utils";
import contracts from "../constants/contracts";
import abi from "../constants/abi";
const CreateCampaign = () => {
  const { account, signer, activate } = useWeb3React();
  const navigate = useNavigate();

  const [crfee, setcrfee] = useState(0.02);

  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [form, setForm] = useState({
    name: "",
    title: "",
    story: "",
    target: "",
    deadline: "",
    image: "",
    imageFile: null,
  });

  // Initialize web3 instance
  const web3 = new Web3(window.ethereum);

  const campaignFactoryAddress = contracts.Factory; // Add your CampaignFactory contract address here
  const campaignFactoryContract = new web3.eth.Contract(
    abi.Factory,
    campaignFactoryAddress,
    signer
  );

  useEffect(() => {
    async function getData() {
      let creationFeeis = await campaignFactoryContract.methods
        .creationFee()
        .call();
      setcrfee(Number(creationFeeis));
    }
    getData();
  }, [account]);

  const handleFormFieldChange = (fieldName, e) => {
    try {
      if (fieldName === "image") {
        const file = e.target.files[0];
        setImageFile(file);
        setForm({ ...form, image: file ? URL.createObjectURL(file) : "" });
      } else {
        setForm({ ...form, [fieldName]: e.target.value });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      // Log form values for debugging
      // console.log("Form Values:", form);

      setIsLoading(true);

      // Convert goal amount to wei and duration to seconds
      const goalAmount = form.target;
      const duration =
        Math.floor(new Date(form.deadline).getTime() / 1000) -
        Math.floor(Date.now() / 1000);
      const isRepayment = form.isRepayment;

      // Creation fee (you can adjust this as per your contract)
      // const creationFee = web3.utils.toWei("0.01", "ether");
      let params = {
        goalAmount: goalAmount, // Required (converted to wei earlier)
        duration: duration, // Required (calculated in seconds)
        isRepayment: true, // Required (boolean)
        title: form.title, // Required (string)
        description: "aaaaaaaaa",
        story: form.story, // Required (string)
        imageUrl: form.image, // Required (string)
      };
      //   struct CampaignParams {
      //     uint goalAmount;
      //     uint duration;
      //     bool isRepayment;
      //     string title;
      //     string description;
      //     string story;
      //     string imageUrl;
      // }
      console.log("campaign params :", params);
      // Call the contract method with all the required parameters
      const tx = await campaignFactoryContract.methods
        .createCampaign(params)
        .send({
          from: account,
          value: crfee, // Attach the creation fee
        });

      console.log("Transaction successful:", tx);

      // Navigate the user to a success page or dashboard
      setIsLoading(false);
      navigate("/");
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating campaign:", error);
      // alert("Failed to create campaign. Please try again.");
    }
  };

  // Placeholder function for image upload
  const uploadImageAndGetUrl = async (file) => {
    // In a real application, you would upload the file to a server here
    // and return the URL of the uploaded image.
    // For this example, we'll just return a fake URL
    return URL.createObjectURL(file);
  };
  return (
    <div className="bg-[#1c1c24] flex justify-center items-center flex-col rounded-[10px] sm:p-10 p-4">
      {isLoading && <Loader />}
      <div className="flex justify-center items-center p-[16px] sm:min-w-[380px] bg-[#3a3a43] rounded-[10px]">
        <h1 className="font-epilogue font-bold sm:text-[25px] text-[18px] leading-[38px] text-white">
          Start a Campaign
        </h1>
      </div>

      <form
        onSubmit={handleSubmit}
        className="w-full mt-[65px] flex flex-col gap-[30px]"
      >
        <div className="flex flex-wrap gap-[40px]">
          <FormField
            labelName="Your Name *"
            placeholder="John Doe"
            inputType="text"
            value={form.name}
            handleChange={(e) => handleFormFieldChange("name", e)}
          />
          <FormField
            labelName="Campaign Title *"
            placeholder="Write a title"
            inputType="text"
            value={form.title}
            handleChange={(e) => handleFormFieldChange("title", e)}
          />
        </div>

        <FormField
          labelName="Story *"
          placeholder="Write your story"
          isTextArea
          value={form.story}
          handleChange={(e) => handleFormFieldChange("story", e)}
        />

        <div className="w-full flex justify-start items-center p-4 bg-[#8c6dfd] h-[120px] rounded-[10px]">
          <img
            src={money}
            alt="money"
            className="w-[40px] h-[40px] object-contain"
          />
          <h4 className="font-epilogue font-bold text-[25px] text-white ml-[20px]">
            You will get 91% of the raised amount
          </h4>
        </div>

        <div className="flex flex-wrap gap-[40px]">
          <FormField
            labelName="Goal *"
            placeholder="ETH 0.50"
            inputType="text"
            value={form.target}
            handleChange={(e) => handleFormFieldChange("target", e)}
          />
          <FormField
            labelName="End Date *"
            placeholder="End Date"
            inputType="date"
            value={form.deadline}
            handleChange={(e) => handleFormFieldChange("deadline", e)}
          />
        </div>

        <FormField
          labelName="Campaign image URL *"
          placeholder="Place image URL of your campaign"
          inputType="url"
          value={form.image}
          handleChange={(e) => handleFormFieldChange("image", e)}
        />

        <div className="flex flex-col mt-[15px] p-6 bg-[#2c2f32] rounded-[10px] border-2 border-[#3a3a43] shadow-lg">
          <label className="font-epilogue font-medium text-[16px] leading-[22px] text-white mb-[15px]">
            Campaign Image *
          </label>
          <div className="relative">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFormFieldChange("image", e)}
              className="hidden"
              id="file-upload"
            />
            <label
              htmlFor="file-upload"
              className="py-[15px] sm:px-[25px] px-[15px] w-full flex items-center justify-center outline-none border-[1px] border-[#3a3a43] bg-gradient-to-r from-[#8c6dfd] to-[#1dc071] font-epilogue text-white text-[14px] rounded-[10px] cursor-pointer transition duration-300 hover:opacity-90"
            >
              <span className="mr-2">📁</span>
              Choose File
            </label>
          </div>
          {form.image && (
            <div className="mt-4 p-2 bg-[#1c1c24] rounded-md">
              <img
                src={form.image}
                alt="Campaign"
                className="rounded-md w-full max-w-[300px] mx-auto"
              />
            </div>
          )}
        </div>

        <div className="flex justify-center items-center mt-[40px]">
          <CustomButton
            btnType="submit"
            title="Submit new campaign"
            styles="bg-[#1dc071]"
          />
        </div>
      </form>
    </div>
  );
};

export default CreateCampaign;
