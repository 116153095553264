import React from "react";
import { FaDiscord, FaTwitter, FaEnvelope } from "react-icons/fa";
import Navbar from "./Navbar";

const Contact = () => {
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div
        style={{
          background: "linear-gradient(to bottom right, #1c1c24, #2d2d3a)",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "16px",
        }}
      >
        <div
          style={{
            backgroundColor: "#3a3a43",
            padding: "40px",
            borderRadius: "20px",
            boxShadow: "0 8px 30px rgba(0, 0, 0, 0.2)",
            maxWidth: "500px",
            width: "100%",
            textAlign: "center",
          }}
        >
          <h1
            style={{
              fontSize: "2.5rem",
              fontWeight: "800",
              background: "linear-gradient(to right, #a855f7, #ec4899)",
              backgroundClip: "text",
              color: "transparent",
              marginBottom: "32px",
              padding: "36px 0",
            }}
          >
            Get in Touch
          </h1>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "24px" }}
          >
            <ContactItem
              icon={
                <FaDiscord style={{ color: "#7289da", fontSize: "2rem" }} />
              }
              platform="Discord"
              contact="Join our community"
              link="https://discord.gg/q49drhkrXD"
            />
            <ContactItem
              icon={
                <FaTwitter style={{ color: "#1da1f2", fontSize: "2rem" }} />
              }
              platform="Twitter"
              contact="@yourhandle"
              link="https://x.com/SEIZE_web3"
            />
            <ContactItem
              icon={
                <FaEnvelope style={{ color: "#ea4335", fontSize: "2rem" }} />
              }
              platform="Email"
              contact="contact@example.com"
              link="mailto:seize@example.com"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactItem = ({ icon, platform, contact, link }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    style={{
      display: "flex",
      alignItems: "center",
      padding: "24px",
      backgroundColor: "#2c2f32",
      borderRadius: "12px",
      textDecoration: "none",
      color: "white",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      transition: "transform 0.3s ease-in-out",
    }}
    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
  >
    <div
      style={{
        backgroundColor: "#1c1c24",
        padding: "12px",
        borderRadius: "50%",
        marginRight: "16px",
      }}
    >
      {icon}
    </div>
    <div>
      <h2 style={{ fontSize: "1.25rem", fontWeight: "600" }}>{platform}</h2>
      <p style={{ color: "#a0aec0", marginTop: "4px" }}>{contact}</p>
    </div>
  </a>
);

export default Contact;
