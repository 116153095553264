"use client";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import React from "react";
import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import UpperPart from "./Components/UpperPart";
import video from "./images/h.mp4";

function App() {
  const navigate = useNavigate();

  const fadeInLeft = {
    opacity: 1,
    transform: "translateX(0)",
    transition: "opacity 0.8s ease-in-out, transform 0.8s ease-in-out",
  };

  const fadeInRight = {
    opacity: 1,
    transform: "translateX(0)",
    transition:
      "opacity 0.8s ease-in-out 0.5s, transform 0.8s ease-in-out 0.5s",
  };

  const scaleHover = {
    transform: "scale(1.05)",
    transition: "transform 0.3s ease-in-out",
  };

  const scaleHoverButton = {
    transform: "scale(1.05)",
    boxShadow: "0px 0px 8px rgb(255,0,0)",
    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  };

  const buttonStyles = {
    backgroundColor: "#dc2626",
    padding: "12px 24px",
    borderRadius: "8px",
    color: "white",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
  };

  const opportunityStyles = {
    fontSize: "3rem",
    color: "red",
    transition: "transform 0.3s ease-out",
    cursor: "pointer",
  };

  return (
    <div style={{ backgroundColor: "black", minHeight: "100vh" }}>
      <UpperPart />

      <div
        style={{ position: "relative", height: "100vh", overflow: "hidden" }}
      >
        <video
          autoPlay
          loop
          muted
          style={{
            position: "absolute",
            inset: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: 0,
          }}
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          style={{
            position: "absolute",
            inset: 0,
            backgroundColor: "black",
            opacity: 0.5,
            zIndex: 10,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            inset: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 20,
          }}
        >
          <div style={{ textAlign: "center", padding: "0 16px" }}>
            <h1
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
                color: "white",
                marginBottom: "16px",
              }}
            >
              <p style={fadeInLeft}>IT'S</p>
              <p style={fadeInRight}>YOUR</p>
              <p
                style={opportunityStyles}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.transform = "translateY(-5px)")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.transform = "translateY(0)")
                }
              >
                OPPORTUNITY
              </p>
            </h1>
            <button
              style={buttonStyles}
              onClick={() => navigate("/upload")}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = scaleHoverButton.transform;
                e.currentTarget.style.boxShadow = scaleHoverButton.boxShadow;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "scale(1)";
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              SEIZE CAMPAIGNS
            </button>
          </div>
        </div>
      </div>

      <div
        style={{ backgroundColor: "black", color: "white", padding: "64px 0" }}
      >
        <div
          style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 16px" }}
        >
          <h2
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "48px",
            }}
          >
            Join Us
          </h2>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "32px" }}
          >
            <a
              href="https://x.com/SEIZE_web3"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                transform: "scale(1)",
                transition: "transform 0.3s ease-in-out",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.1)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <FaXTwitter style={{ width: "48px", height: "48px" }} />
            </a>
            <a
              href="https://discord.gg/q49drhkrXD"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                transform: "scale(1)",
                transition: "transform 0.3s ease-in-out",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.1)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <FaDiscord
                style={{ width: "48px", height: "48px", color: "#5865f2" }}
              />
            </a>
          </div>
        </div>
      </div>

      <footer
        style={{ backgroundColor: "black", color: "white", padding: "48px 0" }}
      >
        <div
          style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 16px" }}
        >
          <div
            style={{ borderBottom: "1px solid black", marginBottom: "32px" }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              Seize Your <span style={{ color: "red" }}>Opportunity</span>
            </p>
            <div style={{ display: "flex", gap: "24px" }}>
              <a
                href="#"
                style={{
                  color: "white",
                  textDecoration: "none",
                  transition: "transform 0.3s ease-in-out",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.transform = "scale(1.1)")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                Privacy Policy
              </a>
              <a
                href="#"
                style={{
                  color: "white",
                  textDecoration: "none",
                  transition: "transform 0.3s ease-in-out",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.transform = "scale(1.1)")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                Terms of Service
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
