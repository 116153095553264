import React, { useState, useEffect } from "react";
import Navbar from "./Navbar.jsx";
import { FaDiscord, FaTwitter, FaSteam } from "react-icons/fa";
import video2 from "../images/Scene-1.mp4";

const Client1 = () => {
  const [selectedTier, setSelectedTier] = useState(null);
  const [hoveredTier, setHoveredTier] = useState(null);
  const [currentAccount, setCurrentAccount] = useState("");
  const [tierCounts, setTierCounts] = useState({
    "Tier 1": 0,
    "Tier 2": 0,
    "Tier 3": 0,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const checkIfWalletIsConnected = async () => {
    try {
      if (!window.ethereum) {
        setSnackbar({
          open: true,
          message: "Please install MetaMask.",
          severity: "error",
        });
        return;
      }

      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      if (accounts.length) {
        setCurrentAccount(accounts[0]);
      } else {
        console.log("No accounts found");
      }
    } catch (error) {
      console.log(error);
      throw new Error("No ethereum object");
    }
  };

  const handleTierClick = (tier) => {
    setSelectedTier(tier === selectedTier ? null : tier);
    const price = tier === "Tier 1" ? 50 : tier === "Tier 2" ? 100 : 150;

    if (!currentAccount) {
      setSnackbar({
        open: true,
        message: "Please connect your wallet first.",
        severity: "warning",
      });
      return;
    }
  };

  useEffect(() => {
    checkIfWalletIsConnected();
  }, []);

  const getTierClassName = (tier) => {
    const baseClass =
      "p-6 rounded-lg shadow-lg transition-all duration-300 cursor-pointer";
    const selectedClass =
      selectedTier === tier ? "transform scale-105 ring-4 ring-red-400" : "";
    const hoverClass = hoveredTier === tier ? "transform scale-105" : "";
    const tierColorClass =
      tier === "Tier 1"
        ? "bg-gradient-to-br from-red-700 to-red-900"
        : tier === "Tier 2"
        ? "bg-gradient-to-br from-red-800 to-red-950"
        : "bg-gradient-to-br from-red-900 to-black";
    return `${baseClass} ${selectedClass} ${hoverClass} ${tierColorClass}`;
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div className="relative">
      <div className="absolute top-0 left-0 w-full z-10">
        <Navbar />
      </div>
      <div className="relative w-full h-screen overflow-hidden">
        <video
          src={video2}
          muted
          autoPlay
          loop
          playsInline
          className="absolute top-0 left-0 w-full h-full object-cover"
        ></video>
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50"></div>
        <button
          onClick={() => {
            const mainContent = document.querySelector(".container");
            mainContent.scrollIntoView({ behavior: "smooth" });
          }}
          style={{
            position: "absolute",
            bottom: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#dc2626",
            color: "white",
            fontWeight: "bold",
            padding: "8px 16px",
            borderRadius: "9999px",
            transition: "all 0.3s ease-in-out",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Explore</span>
          <svg
            style={{
              width: "20px",
              height: "20px",
              marginLeft: "8px",
              transition: "transform 0.3s ease-in-out",
            }}
            viewBox="0 0 24 24"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          >
            <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
          </svg>
        </button>
      </div>

      <div className="bg-gradient-to-b from-transparent to-red-900 text-white relative z-20 min-h-screen">
        <div className="container mx-auto px-4 py-12 pt-24">
          <div className="bg-gradient-to-r from-red-800 to-black p-8 rounded-xl shadow-2xl mb-12">
            <h2 className="text-4xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-red-400 to-white">
              EgoVersus: The First Strike $50,000 Fundraising Campaign
            </h2>
            <p className="text-lg text-gray-200 leading-relaxed">
              We are thrilled to announce our $50,000 fundraising campaign to
              fuel the development of EgoVersus: The First Strike.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            {["Tier 1", "Tier 2", "Tier 3"].map((tier) => (
              <div
                key={tier}
                className={getTierClassName(tier)}
                onClick={() => handleTierClick(tier)}
                onMouseEnter={() => setHoveredTier(tier)}
                onMouseLeave={() => setHoveredTier(null)}
              >
                <h3 className="text-2xl font-semibold mb-3 text-white">
                  {tier}
                </h3>
                <p className="font-bold mb-2 text-red-300">
                  Price: $
                  {tier === "Tier 1" ? "50" : tier === "Tier 2" ? "100" : "150"}
                  (Max{" "}
                  {tier === "Tier 1"
                    ? "300"
                    : tier === "Tier 2"
                    ? "200"
                    : "100"}{" "}
                  Packages)
                </p>
                <ul className="list-disc list-inside text-gray-200">
                  <li>
                    {tier === "Tier 1" ? "1" : tier === "Tier 2" ? "2" : "5"}{" "}
                    Alter Ego Hunter NFTs
                  </li>
                  <li>
                    Early Investor Role & Access to Early Investor Channel
                  </li>
                  <li>Exclusive Weapon Skin Package</li>
                  <li>Access to Early Beta Testing</li>
                  {tier === "Tier 3" && (
                    <li>Custom Legendary 3D Character Added to the Game</li>
                  )}
                </ul>
                <p className="mt-4 text-center font-bold text-red-300">
                  {tierCounts[tier]}/
                  {tier === "Tier 1"
                    ? "300"
                    : tier === "Tier 2"
                    ? "200"
                    : "100"}{" "}
                  Funded
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <footer className="bg-black py-12">
        <div className="container mx-auto px-4">
          <div className="flex justify-center space-x-8">
            <a
              href="https://discord.gg/wYCqjCzYhW"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                background: "linear-gradient(to right, #6B46C1, #5A67D8)",
                color: "white",
                padding: "12px",
                borderRadius: "50%",
                transition: "transform 0.3s ease-in-out",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.1)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <FaDiscord size={28} />
            </a>
            <a
              href="https://x.com/TheEgoVerse"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                background: "linear-gradient(to right, #4299E1, #2B6CB0)",
                color: "white",
                padding: "12px",
                borderRadius: "50%",
                transition: "transform 0.3s ease-in-out",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.1)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <FaTwitter size={28} />
            </a>
            <a
              href="https://store.steampowered.com/app/3076680/EgoVersus_The_First_Strike/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                background: "linear-gradient(to right, #4A5568, #2D3748)",
                color: "white",
                padding: "12px",
                borderRadius: "50%",
                transition: "transform 0.3s ease-in-out",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.1)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <FaSteam size={28} />
            </a>
          </div>
        </div>
      </footer>

      {snackbar.open && (
        <div
          className={`fixed right-4 bottom-4 px-6 py-4 rounded-xl shadow-2xl z-50 ${
            snackbar.severity === "success"
              ? "bg-green-500"
              : snackbar.severity === "error"
              ? "bg-red-500"
              : snackbar.severity === "warning"
              ? "bg-yellow-500"
              : "bg-blue-500"
          } text-white`}
          style={{ transition: "all 0.5s ease-in-out" }}
        >
          <div className="flex items-center">
            <span className="mr-3 text-2xl">
              {snackbar.severity === "success"
                ? "🎉"
                : snackbar.severity === "error"
                ? "❌"
                : snackbar.severity === "warning"
                ? "⚠️"
                : "ℹ️"}
            </span>
            <p className="font-semibold text-lg">{snackbar.message}</p>
            <button
              onClick={handleCloseSnackbar}
              style={{
                marginLeft: "16px",
                color: "white",
                transition: "transform 0.2s ease-in-out",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.1)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Client1;
