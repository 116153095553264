import sample from "lodash/sample";

export const CHAIN_ID = 97;

export const RPC_URL = "https://bsc-testnet.public.blastapi.io";
export const NODES = {
  CHAIN_ID: [RPC_URL],
};

const getNodeUrl = () => {
  const nodeUrl = sample(NODES[CHAIN_ID]);
  if (nodeUrl === undefined) {
    return NODES[CHAIN_ID][0];
  }
  return nodeUrl;
};

export default getNodeUrl;
