import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider, http } from "wagmi";
import "./index.css";
import App from "./App";
import "./App.css"; // Import global styles if you have any
import {
  metaMaskWallet,
  rainbowWallet,
  trustWallet,
  compassWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { rabbyWallet } from "@rainbow-me/rainbowkit/wallets";

import { Web3ReactProvider } from "@web3-react/core";
import { getLibrary } from "./utils/web3React";
import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ReactDOM from "react-dom/client";
import { bscTestnet } from "wagmi/chains";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { getAccount } from "@wagmi/core";

const config = getDefaultConfig({
  appName: "sieze",
  projectId: "39482929",
  chains: [bscTestnet],
  wallets: [
    {
      groupName: "Recommended",
      wallets: [
        compassWallet,
        metaMaskWallet,
        rabbyWallet,
        rainbowWallet,

        trustWallet,
      ],
    },
  ],
});

const queryClient = new QueryClient();
const account = getAccount(config);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <RainbowKitProvider initialChain={97}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <App />{" "}
          </Web3ReactProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);
