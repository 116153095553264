import { InjectedConnector } from "@web3-react/injected-connector";

import { CHAIN_ID } from "./getRpcUrl";
import { RPC_URL } from "./getRpcUrl";

const ethers = require("ethers");
const injected = new InjectedConnector({
  supportedChainIds: [CHAIN_ID],
});
export const simpleRpcProvider = new ethers.providers.JsonRpcProvider(RPC_URL);

export const connectors = {
  injected,
};

export const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 3000;
  return library;
};
